import { TBlockName } from '@/views/admin/constructor-event/ConstructorEventPageTypes';

export const EVENT_REDACTOR_INFO_SLIDER_TITLE_LENGTH = 50;
export const EVENT_REDACTOR_INFO_SLIDER_TITLE_DESCRIPTION = 320;

export const EVENT_BLOCKS_NAMES: TBlockName[] = [
  'HERO',
  'INFO',
  'PROGRAM',
  'DOCUMENT',
  'RECOMMENDATION',
  'SPEAKER',
  'SURVEY',
  'NEWS',
  'SLIDER',
  'VIDEO',
  'VIDEO_WITH_TEXT',
  'GALLERY',
  'ADS',
  'PARTNER',
  'GALLERY_WITH_TEXT',
];
