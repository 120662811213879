<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-align-text="true"
    text-type="fourthTitle"
    text-head="Образование и повышение квалификации"
    :is-confirm-exit="true"
  >
    <div class="app-modal-profile-edit-education">
      <div class="app-modal-profile-edit-education__date">
        <app-input
          label="Начало обучения"
          placeholder="Введите дату"
          :value="inputValueStartDate.split('.').reverse().join('-')"
          :errors="errorStartDate"
          type="date"
          @onInput="
            (val) => {
              inputValueStartDate = val;
            }
          "
        />
        <app-input
          v-if="!isUntilNow"
          label="Окончание"
          placeholder="Введите дату"
          type="date"
          :value="inputValueEndDate.split('.').reverse().join('-')"
          :errors="errorEndDate"
          @onInput="
            (val) => {
              inputValueEndDate = val;
            }
          "
        />
      </div>
      <app-check-box
        :is-checked="isUntilNow"
        class="app-modal-profile-edit-education__checkbox"
        text="По настоящее время"
        @input="toggleIsUntilNow"
        type-text="primary"
      />
      <app-input
        placeholder="Введите место обучения"
        label="Место"
        :value="inputValueOrganizationName"
        @onInput="
          (val) => {
            inputValueOrganizationName = val;
          }
        "
      />
      <app-input
        placeholder="Введите факультет обучения"
        label="Факультет"
        :value="inputValueFaculty"
        @onInput="
          (val) => {
            inputValueFaculty = val;
          }
        "
      />
    </div>
    <app-button
      text="Сохранить изменения"
      @click="saveProfileEducation"
      :is-disabled="isActiveSubmitButton"
    />
  </AppModal>
</template>
<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppButton, AppInput } from '@/ui/components';
import { mapActions, mapGetters, mapState } from 'vuex';
import { formattedDate } from '@/utils/custom';
import { EX_$Toaster } from '@/classes/content';
import { ICountry, IEducationPath } from '@/services/ProfileService/ProfileServiceTypes';
import AppCheckBox from '@/ui/components/switchers/app-check-box/AppCheckBox.vue';

export default Vue.extend({
  components: {
    AppModal,
    AppButton,
    AppInput,
    AppCheckBox,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    activeElement: {
      type: Number,
    },
  },
  data() {
    return {
      EX_Toaster: EX_$Toaster,
      educationPathList: [] as IEducationPath[],
      inputValueStartDate: '',
      inputValueEndDate: '',
      inputValueFaculty: '',
      inputValueOrganizationName: '',
      errorEndDate: [] as Array<string>,
      errorStartDate: [] as Array<string>,
      countryValue: {} as ICountry,
      firstName: '',
      lastName: '',
      isUntilNow: false,
    };
  },
  methods: {
    ...mapActions('profile', ['AChangeProfileEducation', 'AGetProfile']),
    toggleModal() {
      this.$emit('toggleModal');
    },
    toggleIsUntilNow() {
      this.isUntilNow = !this.isUntilNow;
      if (this.isUntilNow) {
        this.inputValueEndDate = '';
      }
    },
    saveProfileEducation() {
      const startDate = `${this.inputValueStartDate
        ?.toString()
        .trim()
        .split('.')
        .reverse()
        .join('-')}T00:00:00.000Z`;
      const endDate = `${this.inputValueEndDate
        ?.toString()
        .trim()
        .split('.')
        .reverse()
        .join('-')}T00:00:00.000Z`;
      const nowDate = Date.now();

      if (this.isUntilNow && Date.parse(startDate) <= nowDate) {
        if (this.activeElement === -1) {
          this.educationPathList.push({
            startDate: this.inputValueStartDate.split('.').reverse().join('-'),
            endDate: null,
            faculty: this.inputValueFaculty,
            organizationName: this.inputValueOrganizationName,
          });
        } else {
          this.educationPathList[this.activeElement] = {
            startDate: this.inputValueStartDate.split('.').reverse().join('-'),
            endDate: null,
            faculty: this.inputValueFaculty,
            organizationName: this.inputValueOrganizationName,
          };
        }
      } else if (
        startDate <= endDate &&
        Date.parse(startDate) <= nowDate &&
        Date.parse(endDate) <= nowDate
      ) {
        if (this.activeElement === -1) {
          this.educationPathList.push({
            startDate: this.inputValueStartDate,
            endDate: this.inputValueEndDate,
            faculty: this.inputValueFaculty,
            organizationName: this.inputValueOrganizationName,
          });
        } else {
          this.educationPathList[this.activeElement] = {
            startDate: this.inputValueStartDate.split('.').reverse().join('-'),
            endDate: this.inputValueEndDate.split('.').reverse().join('-'),
            faculty: this.inputValueFaculty,
            organizationName: this.inputValueOrganizationName,
          };
        }
      }

      if (
        (startDate <= endDate &&
          Date.parse(startDate) <= nowDate &&
          Date.parse(endDate) <= nowDate) ||
        (this.isUntilNow && this.inputValueStartDate && Date.parse(startDate) <= nowDate)
      ) {
        this.AChangeProfileEducation({ educationPath: this.educationPathList });
        this.errorEndDate = [];
        this.errorStartDate = [];
        this.toggleModal();
      } else {
        if (!this.inputValueStartDate) {
          this.errorStartDate = ['Обязательное поле'];
        }
        if (!this.isUntilNow) {
          if (!this.inputValueEndDate) {
            this.errorEndDate = ['Дата конца должна быть заполнена'];
          }
          if (startDate > endDate) {
            this.errorEndDate = ['Дата конца не должна быть меньше чем дата начала'];
          }
          if (Date.parse(endDate) > nowDate) {
            this.errorEndDate = ['Дата конца не может быть в будущем'];
          }
        }
        if (Date.parse(startDate) > nowDate) {
          this.errorStartDate = ['Дата начала не может быть в будущем'];
        }
        this.EX_Toaster.error('Проверьте правильность заполнения полей');
      }
    },
  },
  computed: {
    ...mapState('profile', ['profileInfo']),
    ...mapGetters('profile', ['sortedEducation']),
    getStartDate(): string {
      return formattedDate(this.sortedEducation[this.activeElement].startDate, 'dateFullYear');
    },
    getEndDate(): string {
      return formattedDate(this.sortedEducation[this.activeElement].endDate, 'dateFullYear');
    },
    isActiveSubmitButton(): boolean {
      return (
        (!this.inputValueStartDate ||
          !this.inputValueFaculty ||
          !this.inputValueOrganizationName) &&
        (!this.inputValueEndDate || !this.isUntilNow)
      );
    },
  },
  mounted() {
    this.educationPathList = this.sortedEducation;

    this.countryValue = this.profileInfo?.profile?.countryOfResidence;
    this.firstName = this.profileInfo?.profile?.firstName;
    this.lastName = this.profileInfo?.profile?.lastName;
    if (this.activeElement > -1) {
      this.inputValueStartDate = formattedDate(
        this.sortedEducation[this.activeElement].startDate,
        'dateFullYear'
      );
      if (this.sortedEducation[this.activeElement].endDate !== null) {
        this.inputValueEndDate = formattedDate(
          this.sortedEducation[this.activeElement].endDate,
          'dateFullYear'
        );
      } else {
        this.isUntilNow = true;
      }
      this.inputValueFaculty = this.sortedEducation[this.activeElement].faculty;
      this.inputValueOrganizationName = this.sortedEducation[this.activeElement].organizationName;
    } else {
      this.inputValueStartDate = '';
      this.inputValueEndDate = '';
      this.inputValueFaculty = '';
      this.inputValueOrganizationName = '';
      this.isUntilNow = false;
    }
  },
});
</script>
<style lang="scss" scoped>
@import 'style';
</style>

<style lang="scss">
.app-modal-profile-edit-education__date {
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}
</style>
