<template>
  <img class="w-[44px] h-[44px] rounded-full object-cover" :src="avatarSrc" alt="Аватар" />
</template>

<script lang="ts">
export default { name: 'HeaderUserAvatar' };
</script>

<script setup lang="ts">
import { computed } from 'vue';
import store from '@/store';

const avatarSrc = computed(() => {
  return (
    store.state.profile?.profileInfo?.profile?.avatarUrl ??
    require(`@/assets/images/shared/default-user.svg`)
  );
});
</script>

<style scoped lang="scss"></style>
