<template>
  <div class="box-border">
    <div v-if="!authStore.isLoggedIn">
      <UIButton type="primary" size="large" icon-end="log-in" @click="clickItem('login')">
        Войти
      </UIButton>
    </div>

    <div v-if="authStore.isLoggedIn">
      <nav class="bg-white rounded-md p-2.5">
        <MobileUserItem @click="clickItem('PersonalAccount')">
          <div class="flex justify-between items-center">
            <div class="flex items-center gap-2.5">
              <HeaderUserAvatar />
              <div class="text-lg text-black-50">Личный кабинет</div>
            </div>

            <UIIcon name="chevron-up" :size="24" rotate="90deg" class="stroke-black-50 fill-none" />
          </div>
        </MobileUserItem>

        <MobileUserItem @click="clickItem('ProfilePage')">
          <div class="flex justify-between items-center">
            <div class="text-lg text-black-50">Профиль</div>

            <UIIcon name="chevron-up" :size="24" rotate="90deg" class="stroke-black-50 fill-none" />
          </div>
        </MobileUserItem>

        <MobileUserItem @click="clickItem('logout')">
          <div class="flex justify-between items-center">
            <div class="text-lg text-black-50">Выйти</div>

            <UIIcon name="log-in" :size="24" class="stroke-black-50 fill-none" />
          </div>
        </MobileUserItem>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
export default { name: 'HeaderMobileUser' };
</script>

<script setup lang="ts">
import { useAuthStore } from '@/store/pinia/auth/auth.store';
import MobileUserItem from '@/layouts/Header/Mobile/User/MobileUserItem.vue';
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';
import UIButton from '@/components/UI/UIButton/UIButton.vue';
import { LayoutsService } from '@/services/layouts/layouts.service';
import { LayoutUserItem } from '@/helpers/types/layouts.types';
import HeaderUserAvatar from '@/layouts/Header/shared/HeaderUserAvatar.vue';

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const authStore = useAuthStore();

const clickItem = async (item: LayoutUserItem): Promise<void> => {
  emits('close');
  await LayoutsService.clickUserItem(item);
};
</script>

<style scoped lang="scss"></style>
